import React from 'react'
import { AiFillFacebook, AiFillInstagram, AiFillTwitterCircle, AiOutlineMail } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import "./footer.css"
export default function Footer() {
    return (
        <div>
            <div className='footer'>

                <div className='list-divs'>
                    
                        <h6 style={{marginTop:'5px'}}><Link to='/faq' className='li'>FAQ</Link>  </h6>
                        <h6 style={{marginTop:'5px'}}> <Link to='/safety' className='li'>Safety Tips</Link></h6>
                        <h6 style={{marginTop:'5px'}}> <Link to='/terms' className='li'>Terms of use</Link></h6>
                        <h6 style={{marginTop:'5px'}}><Link to='/privacy' className='li'>Privacy Policy</Link></h6>
                   
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='socials'>
                        <AiFillTwitterCircle size={35} color="blue" />

                    </div>
                    <div className='socials'>
                        <AiFillFacebook size={35} color="darkblue" />

                    </div>
                    <div className='socials'>
                       <a href="mailto:info@waridi.org"><AiOutlineMail size={35} color="red" /></a> 

                    </div>
                    <div className='socials'>
                        <AiFillInstagram size={35} color="purple" />

                    </div>
                </div>
                <div className='list-divs'>
                    <h6 style={{marginTop:'5px'}}><Link to='/features' className='li'>Features</Link>  </h6>
                    <h6 style={{marginTop:'5px'}}> <Link to='/about' className='li'>About Us</Link></h6>
                    <h6 style={{marginTop:'5px'}}>  <Link to='/support' className='li'>Support</Link></h6>
                    <h6 style={{marginTop:'5px'}}><Link to='/shop' className='li'>Shop</Link></h6>


                </div>
            </div>
            <div style={{textAlign:'center',color:'white'}}>
                <h6>&copy;{new Date().getFullYear()} Waridi Group LLC. All Rights Reserved</h6>
            </div>
        </div>
    )
}
