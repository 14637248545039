import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Button, Image, Navbar } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { AiFillAndroid, AiFillSmile } from "react-icons/ai";
import { FaAppStoreIos } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import "./appbar.css";
export default function Appbar() {
  const navigate = useNavigate();
  return (
    <div>
      <Navbar collapseOnSelect expand="lg" fixed='top' >
        <Container fluid className="nav_bar">
          <Navbar.Brand href="/"  style={{color:"whitesmoke",fontWeight:"bolder",fontSize:"24px",display:'flex',alignItems:"center"}}>
            <Image
              src="https://res.cloudinary.com/dkqbmkcp1/image/upload/v1699793947/website/splashscreen_image_a9duza.png"
              width={80}
              height={80}

              alt="Waridi"
            />
            Waridi
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{backgroundColor:"white"}}/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/features" style={{ color: "red", fontWeight: 'bold', fontSize: "20px", marginLeft: '2rem' }}>
                Features
              </Nav.Link>
              <Nav.Link href="/safety" style={{ color: "red", fontWeight: 'bold', fontSize: "20px", marginLeft: '2rem' }}>
                Safety
              </Nav.Link>
              <Nav.Link href="/shop" style={{ color: "red", fontWeight: 'bold', fontSize: "20px", marginLeft: '2rem' }}>
                Shop
              </Nav.Link>
            </Nav>
            <Nav>
              <Button variant="dark" style={{ marginTop: "10px", marginRight: "1rem" }}>
                <span>Download <FaAppStoreIos size={25} style={{ marginBottom: "5px" }} /></span>

              </Button>
              <Button variant="success" style={{ marginTop: "10px", marginRight: "1rem" }}>
                <span>Download <AiFillAndroid size={25} style={{ marginBottom: "5px" }} /></span>

              </Button>
              <Button variant="danger" style={{ marginTop: "10px", marginRight: "1rem" }} onClick={() => navigate("/account")}>
                <span>My Account <AiFillSmile size={25} style={{ marginBottom: "5px" }} /></span>

              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}
