import React from "react";
import {
  Route,
  Routes
} from "react-router-dom";
import Account from "./content/account/account";
import NotFound from "./content/errors/404";
import Home from "./content/home/home";
import Privacy from "./content/legal/privacy";
import "./index.css";
function App() {
  return (
    <div className="App">

      <Routes>

        <Route path='/' element={<Home />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/terms' element={<Privacy />} />
        <Route path='/account' element={<Account />} />
        <Route path='*' element={<NotFound />} />
      </Routes>

    </div>
  );
}

export default App;
