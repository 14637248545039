import React from 'react';
import Appbar from '../../components/appbar';
import Footer from '../../components/footer';
import "./account.css";

export default function Account() {
  return (
    <div>
           <Appbar />
            <div className='main'>

            </div>
           <Footer />
    </div>
  )
}
