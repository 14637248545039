import React from 'react';
import { useNavigate } from 'react-router-dom';

import Appbar from '../../components/appbar';
import Footer from '../../components/footer';
import "./errors.css";

export default function NotFound() {
  const navigate = useNavigate();


  return (
    <>
      <Appbar />
      <div className='error-404'>
        <div>
          <img src='https://res.cloudinary.com/dfnuodjiw/image/upload/v1657661898/photos/user/Pngtree_404_error_interface_scene_art_4043819_hunsn9.png'
            alt="" className='image-404' onClick={e => navigate("/")} />
        </div>

      </div>
      <Footer />
    </>
  )
}
