import React from 'react';
import { Image } from 'react-bootstrap';
import Appbar from '../../components/appbar';
import Footer from '../../components/footer';
import "./home.css";

export default function Home() {
    return (
        <>
            <Appbar />
            <div className='main'>

                <div className='main_left'>
                    <div className="left">
                        Shaping your heart
                    </div>
                    <div className="middle">
                        <Image height="100%" width="100%" src="https://res.cloudinary.com/dkqbmkcp1/image/upload/v1690992158/website/heart_ymyyv3.png" className='image' />
                    </div>
                    <div className="right">
                        Swipe by Swipe
                    </div>
                </div>

                <div className='main_right'>
                   
                        <Image  src="https://res.cloudinary.com/dkqbmkcp1/image/upload/v1704657955/website/phone%20screenshots/main3_anaf5r.jpg" className='image1' />
                        <Image  src="https://res.cloudinary.com/dkqbmkcp1/image/upload/v1704657955/website/phone%20screenshots/main2_ahzt6j.jpg" className='image2' />
                </div>
            </div>
            <Footer />
        </>


    )
}
